
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";
import { visitorService } from "@/service";
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const message = ref("");
    const resultVal = ref("");
    const reasonLabel = ref("");
    const placeholderVal = ref("");
    if (route.query.type == "1") {
      resultVal.value = "同意申请";
      reasonLabel.value = "备注";
      placeholderVal.value = "请输入备注";
    } else {
      resultVal.value = "拒绝申请";
      reasonLabel.value = "拒绝原因";
      placeholderVal.value = "请输入拒绝原因";
    }
    const submit = async () => {
      try {
        const { data } = await visitorService.visitorOpt({
          visitor_id: route.query.id,
          visitor_token: route.query.visitor_token,
          type: route.query.type,
          reason: message.value
        });
        Toast.success("提交成功");
        router.go(-1);
      } catch (error) {}
    };
    return {
      message,
      submit,
      resultVal,
      reasonLabel,
      placeholderVal
    };
  }
});
